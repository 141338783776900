import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"


const PBpage = () => (
    <Layout>
      
      <Seo title="Picture Books | Leah Jordain" />
      <hr className={styles.bluehr}></hr>
      <div className={styles.content}>
        <p>Dear Adriana Domínguez,</p>

        <p>Thank you for your response! I only featured works that had been accepted to mentorships on my website (along with a short story), but I do have several other picture book drafts as listed below. While I write YA, due to the current publishing climate I am hoping to debut with a PB and to stay in that space for several years, possibly indefinitely. Below I have included pitches for several of my works, along with two complete drafts. Please let me know if you have any other questions! Of course I am also happy to chat on the phone if that would be helpful.</p>
        <p>Thanks again,</p>
        <p>- Leah</p>

  
        <hr className={styles.bluepurpleexcerptshr}></hr>
        <h2>Picture Book Pitches</h2>

        <h3>I AM NOT NEITHER, I AM BOTH (full draft below)</h3>
<p>A biracial story of belonging. When a biracial child is asked whether she’s Ecuadorian or American, she realizes that all of her experiences mean that she is both.</p>


<p>Comp titles include <em>Honeysmoke: A Story of Finding Your Color</em> by Monique Fields, and <em>I Am Whole</em> by Shola Oz.</p>

<h3>THE QUILT OF YOU (full draft below)</h3>
<p>A story of how our ancestors each sewed patches in the “quilt” that made us who we are. When an immigrant child is teased at school for being different, her mother shows her that her ancestors had similar struggles, but they all became extraordinary people whose history is worth celebrating. Even if it’s “different.”</p>

<p>Comp titles include <em>Where Are You From?</em> by Yamile Saied Méndez, and <em>Alma and How She Got Her Name</em> by Juana Martinez-Neal.</p>

<h3>JAVIER AND THE FOREST OF FEELINGS</h3>
<p>A story of learning to live with distracting thoughts. Every day, Javier has to go through a deep dark forest to get to school. Lots of feelings live in the forest, and sometimes the ones that come with him are good, and sometimes they’re not. With help from a teacher, Javier learns that he can still live his life, even when the feelings that come with him from the forest are distracting.</p>

<p>Comp titles include <em>My Monster and Me</em> by Nadiya Hussain, and <em>The Whatifs</em> by Emily Kilgore.</p>

<h3>MIA’S MATH</h3>
<p>A dyscalculia story. Mia is frustrated that she can’t do all the math her classmates can. After seeing a psychologist, Mia learns she has a learning disability called dyscalculia—and that though her mind works differently from her classmates’s, it’s okay for everyone to learn in their own ways.</p>

<p>Comp titles include <em>A Walk in the Words</em> by Hudson Talbott, and <em>Brilliant Bea: A Story for Kids With Dyslexia and Learning Differences</em> by      
Shaina Rudolph</p>

<h3>MY DOG ATE THE AÑO VIEJO</h3>
<p>An Ecuadorian New Years story. Every year, Paloma’s family burns a figure called an Año Viejo, Ecuador’s way of saying goodbye to the old year and welcoming the new. But when Paloma’s dog steals the Año Viejo before midnight, she’ll have to chase after them both through the neighborhood, all the while learning about her family’s other New Years traditions.</p>

<p>Comp titles include <em>Seven Special Somethings</em> by Adib Khorram, and <em>My Diwali Light</em> by Raakhee Mirchandani.</p>

<hr className={styles.bluepurpleexcerptshr}></hr>

<h2>Sample Picture Book Full Manuscripts</h2>

<h3>I AM NOT NEITHER, I AM BOTH</h3>

<p>My father is Ecuadorian.<br></br>
My mother is American.<br></br>
“Which one are you?” My friends ask me at school.</p>

<p>“Are you Ecuadorian?”<br></br>
“Are you American?”<br></br>
“Or are you neither?”</p>

<p>“<em>Am</em> I Ecuadorian?” I wonder.<br></br>

“Or am I American?”<br></br>
I am so many things, but I don't know how to answer.</p>

<p>I am soccer, the game I play with my primos from Ecuador, running and laughing in the grass that tickles my legs.<br></br>
I am baseball, the game I play with my friends at school, the wind whipping through my hair.<br></br>
I am not neither, I am both.</p>

<p>I am locro, the sunshine-colored potato stew my ancestors from Ecuador have eaten for hundreds of years.<br></br>
I am chicken soup, the salty and comforting broth my family from America makes me when I’m sick.<br></br>
I am not neither, I am both.</p>

<p>I am Día de los Difuntos, the day my Ecuadorian family gathers and drinks purple juice, celebrating those who have departed from this life.<br></br>
I am Halloween, the day I dress up with my friends and eat candy, celebrating being alive.<br></br>
I am not neither, I am both.</p>


<p>I am the smell of achiote, the ruby-red spice that fills the air in my tío and tía’s kitchen.<br></br>
I am the scent of melted chocolate and marshmallows, the gooey treats I make with my mother’s family around the campfire.<br></br>
I am not neither, I am both.</p>

<p>I am Spanish, the melodic and flowery language of my family from Ecuador, even when I don’t know all the words.<br></br>
I am English, the efficient and practical language of my family from America, even when the words I need are Spanish.<br></br>
I am not neither, I am both.</p>

<p>I am the Andes, a mountain range so tall it can kiss the sky, which my ancestors the Inca called home.<br></br>
I am the Rockies, a giant row of snow-capped peaks, where my American relatives have lived for generations.<br></br>
I am not neither, I am both.</p>

<p>I am pan flute music, the sweet and clear sounds of the Andes, the songs my bisabuelita taught me to play.<br></br>
I am American folk music, the vibrant strum of a guitar, the songs my great grandfather sang to lull me asleep.<br></br>
I am not neither, I am both.</p>

<p>My home is the food, music, colors, family, and culture of Ecuador.<br></br>
My home is the food, music, colors, family, and culture of America.<br></br>
I am not neither, I am both.</p>

<p>I am Ecuadorian.</p>

<p>I am American.</p>

<p>“I am not neither,” I tell my friends.</p>

<p>“I am both.”</p>

<br></br>

<h3>THE QUILT OF YOU</h3>

<p>This is my quilt.</p>


<p>You can’t see it, but it’s there. Thin, filmy, see-through, it rests around my shoulders.</p>


<p>Everyone has one. I have one. You have one. Todo el mundo has one.</p>

<p>“All of us carry the story of how we became who we are,” my mami tells me. “It’s like an invisible quilt, and everyone who came before us sewed a patch.”</p>

<p>But my quilt isn’t like the others at my school. “Yours is different from ours,” my classmates tell me. “You don’t belong with us.”</p>

<p>At school we’ve learned about the people who made their quilts. My classmates are descended from inventors, artists, engineers, musicians, builders, and scientists, and all of them have beautiful stories. But I am from another country. And we do not learn those stories.</p>

<p>“I hate my quilt,” I tell Mami at home. “It makes me different.”</p>


<p>Mami only smiles and takes me on her knee. “Each of our quilts is something to be proud of,” Mami says. She points. “See this patch?”</p>


<p>“This is your ancestor, one of the first humans to ever live in South America. She ran under blue skies and the <em>whoosh</em> of condor wings, and like you, she worried about being different as she sewed a patch of your quilt.”</p>

<p>“This is your ancestor, who helped build loud and bustling places like Machu Picchu, a mountain city that touched the clouds. No one had done that before, but she pushed past her fears of being different to make something new while she sewed a patch of your quilt.”</p>

<p>“This is your ancestor. They helped carve grooves in the earth called Ceque Lines, which connected sacred shrines throughout our homeland. And as they looked at their work, which is still visible even today, they realized that this new thing they’d pushed past their fears to try was worth it.”</p>

<p>“Then conquerors from faraway came, and they took away your ancestors’s land. The conquerors tried to make your ancestors just like them, but your many-times great grandfather resisted. As he sewed a patch in your quilt, he realized that his experiences were different from theirs, but just as valuable, and he wanted to honor them always.”</p>

<p>“This is your great great great grandmother’s great great great grandmother. Three hundred years after the conquerors came, she fought in a war to make South America independent again. And as she stood with the wind of freedom in her hair, sewing a patch of your quilt, she realized she didn’t want to be like anybody else. She wanted to be herself.”</p>

<p>“This is me, your Mami. One day, I left my home in South America and got on a plane with you. We traveled thousands of miles to live in America, passing through clouds that touched Machu Picchu. When I got here, I was different from everyone else. But like our ancestors, I realized that being different is extraordinary. In America I sewed the most recent patch of your quilt, and all the stories of this country you hear in school became a part of your story too.”</p>

<p>Mami sets me down, and my quilt feels different now. It doesn’t seem so bad that it’s not like my classmates’s. I look down at it.</p>

<p>This is my quilt.</p>

 
<p>It was made by inventors, artists, engineers, musicians, builders, and scientists, and they all have beautiful stories. It was made by people from North America, South America, and all over the world.</p>

<p>It is the story of everyone who came before me, who made me who I am.</p>

<p>And I am proud of my quilt.</p>

<p>Who made your quilt?</p>




      </div>
  
  
    </Layout>
  )
  
  export default PBpage